import './App.css';
import { Flex } from '@chakra-ui/react'
import nfagfydyor from './assets/godfather_logo_white.png'


function App() {
  return (
    <div className="App">
      <Flex justify="center" align="center" paddingTop="0px">
      <div >
        <img src={nfagfydyor} alt=''/>
      </div>
      </Flex>
    </div>
  );
}

export default App;